import React from "react"
import Layout from "../components/layout"
import MessengerChat from "../components/messenger"
import SEO from "../components/seo"
import Header from "../components/common/header"
import { ParallaxProvider } from "react-scroll-parallax"
import { Col, Container, Row, Text } from "atomize"
import Craft from "../components/homepage/Craft"

const help = () => {
  const layout = "layout-2"
  return (
    <Layout>
      <MessengerChat />

      <React.Fragment>
        <Header layout={layout} />
      </React.Fragment>

      <ParallaxProvider>
        <div style={{ height: "128px" }} />
        <section id="#steps">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "1rem",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Container d="flex" flexDir="column" align="left">
              <Text
                tag="h1"
                textWeight="700"
                textAlign="center"
                textSize="display3"
                fontFamily="secondary"
                textColor={"black"}
                m={{ b: "3rem" }}
              >
                Hướng dẫn sử dụng
              </Text>

              <iframe
                width="100%"
                height="400px"
                src="https://www.youtube.com/embed/AnkN1lRjRqg"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </Container>

            <div style={{ maxWidth: "640px" }}>
              <div style={{ margin: "1rem 0" }}>
                <b style={{ fontSize: "20px" }}>
                  Bước 1: Nhập danh sách khách hàng
                </b>
                <p>Tải xuống DSKH mẫu</p>
                <p>Tải lên DSKH</p>
              </div>
              <div style={{ margin: "1rem 0" }}>
                <b style={{ fontSize: "20px" }}>Bước 2: Thiết kế kịch bản</b>
                <iframe
                  width="100%"
                  height="315"
                  src="https://www.youtube.com/embed/1cgonDJmTw4"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
              <div style={{ margin: "1rem 0" }}>
                <b style={{ fontSize: "20px" }}>Bước 3: Cài đặt chiến dịch</b>
                <p>
                  Chọn giọng nói phù hợp với chiến dịch và tuỳ chỉnh tốc độ mong
                  muốn
                </p>
                <p>Chọn khoảng thời gian chạy chiến dịch (không bắt buộc)</p>
                <p>
                  Tuỳ chọn gọi lại khi khách hàng không nghe máy (không bắt
                  buộc)
                </p>
              </div>
              <div style={{ margin: "1rem 0" }}>
                <b style={{ fontSize: "20px" }}>
                  Bước 4: Chạy và báo cáo chiến dịch
                </b>
                <p>
                  Bấm gọi thử để nghe cuộc gọi mẫu, đảm bảo chiến dịch như ý bạn
                </p>
                <p>Bấm chạy chiến dịch rồi pha 1 ly cafe thưởng thức</p>
                <p>
                  Xem báo cáo tổng quan để biết tiến độ chiến dịch và thống kê
                  những chỉ số quan trọng của chiến dịch
                </p>
                <p>
                  Xuất file excel chi tiết để lọc, phân loại khách hàng từ chiến
                  dịch
                </p>
              </div>
            </div>
          </div>
        </section>

        <div style={{ height: "128px" }} />
      </ParallaxProvider>
      <Container>
        <Text></Text>
      </Container>
    </Layout>
  )
}
export default help
